<template>
  <div class="lineDiv" ref="dashboardLineChart"></div>
</template>

<script>
/*
 * 传x轴(日期)，默认为空
 * 传Y轴（数据），默认为空
 * */

import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer]);
//对Proxy对象进行拆箱。
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
import { nextTick } from "vue";

export default {
  name: "dashboard-line-bar",
  data() {
    return {
      myChart: "",
      timer: null,
      defaultColors: [
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ],
      interval: 0,
      leftyAxisMax: 0,
      rightyAxisMax: 0,
    };
  },
  props: {
    xAxis: {
      type: Object,
      default() {
        return {
          type: "category",
          data: [],
        };
      },
    },
    yAxisNames: {
      type: Object,
      default() {
        return {
          firstName: "",
          secondName: "",
        };
      },
    },
    yAxis: {
      type: Object,
      default() {
        return {
          type: "value",
        };
      },
    },
    series: {
      type: Array,
      default() {
        return [
          {
            type: "line",
            name: "全体网民触达",
            data: [],
            smooth: true,
          },
        ];
      },
    },
    color: {
      typ: Array,
      default() {
        return ["#113491", "#bfd9ef", "#65a6ea"];
      },
    },
  },
  computed: {
    formatSeries() {
      return this.series.map((item) => {
        console.log(item);
        return Object.assign(
          {
            lineStyle: {
              width: 4,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#999", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#eee", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              opacity: 0.1,
            },
          },
          item
        );
      });
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs.dashboardLineChart);
      this.resize();
    });
  },
  beforeUnmount() {
    console.log("beforeDestroy");
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    switchToEn(str) {
      switch (str) {
        case "cost":
          return "费用";
        case "exposure":
          return "曝光";
        case "engagement":
          return "互动";
        case "sales":
          return "销量";
        case "searchRate":
          return "搜索指数";
      }
    },
    drawLineBar() {
      console.log(this.leftyAxisMax, this.rightyAxisMax);
      unwarp(this.myChart).setOption(
        {
          grid: {
            show: false,
            top: 30,
            right: 60,
            bottom: 30,
            left: 14,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "none",
            },
            formatter: (params) => {
              let maker = "";
              let opcaityMaker = "";
              let str = `<p>${params[0].name}</p>`;
              params.forEach((v, i) => {
                if (this.formatSeries[i]?.opacity) {
                  console.log("hahahah");
                  opcaityMaker = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${v.color};opacity: 0.5;"></span>`;
                  str += `<p>${opcaityMaker}${v.seriesName}-${this.switchToEn(
                    this.formatSeries[i]?.selType
                  )}&nbsp;&nbsp;${v.value ? this.sliceDecimal(v.value) : "--"}`;
                } else {
                  maker = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${v.color};"></span>`;
                  str += `<p>${maker}${v.seriesName}-${this.switchToEn(
                    this.formatSeries[i]?.selType
                  )}&nbsp;&nbsp;${v.value ? this.sliceDecimal(v.value) : "--"}`;
                }
              });
              return str;
            },
          },
          xAxis: Object.assign(
            {
              boundaryGap: false,
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                margin: 20,
                fontSize: 14,
                formatter: (value) => {
                  const i = value.indexOf(" ~ ");
                  return i === -1
                    ? value
                    : value.slice(0, i + 3) + "\n" + value.slice(i + 3);
                },
              },
              splitLine: {
                show: false,
              },
            },
            this.xAxis
          ),
          yAxis: [
            Object.assign(
              {
                axisTick: {
                  show: false,
                },
                interval: this.leftyAxisMax,
                max: (value) => {
                  console.log(this.leftyAxisMax, this.rightyAxisMax, "123");
                  return value.max;
                },
                axisLine: {
                  show: false,
                },
                axisLabel: {
                  margin: 20,
                  fontSize: 14,
                  formatter: (value) => {
                    return value > 1000000
                      ? (value / 1000000).toFixed(1) + "M"
                      : value > 1000
                      ? (value / 1000).toFixed(1) + "K"
                      : value;
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#ECE9F1",
                  },
                },
                name: this.switchToEn(this.yAxisNames.firstName),
              },
              this.yAxis
            ),
            Object.assign(
              {
                axisTick: {
                  show: false,
                },
                max: function (value) {
                  return value.max;
                },
                interval: this.rightyAxisMax,
                axisLine: {
                  show: false,
                },
                axisLabel: {
                  margin: 20,
                  fontSize: 14,
                  formatter: (value) => {
                    return value > 1000000
                      ? (value / 1000000).toFixed(1) + "M"
                      : value > 1000
                      ? (value / 1000).toFixed(1) + "K"
                      : value;
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#ECE9F1",
                  },
                },
                name: this.switchToEn(this.yAxisNames.secondName),
              },
              this.yAxis
            ),
          ],
          series: this.formatSeries,
          textStyle: {
            fontFamily: "Microsoft YaHei",
            color: "#A2A3A5",
          },
          color: this.color.concat(this.defaultColors),
        },
        true
      );
    },
    resize() {
      this.myChart.resize();
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          console.log("我执行了resize");
          this.myChart.resize();
        }, 500);
      });
    },
  },
  watch: {
    series: {
      handler(val) {
        console.log(val, "hahahahahahaha");
        let leftArr = [];
        let rightArr = [];
        val.forEach((v) => {
          if (v.yAxisIndex !== 1) {
            leftArr.push(...v.data);
          } else {
            rightArr.push(...v.data);
          }
        });
        if (leftArr.length !== 0) {
          this.leftyAxisMax =
            Math.max.apply(null, leftArr) === 0
              ? 100
              : Math.max.apply(null, leftArr) / 5;
        }
        if (rightArr.lenght !== 0) {
          this.rightyAxisMax =
            Math.max.apply(null, rightArr) === 0
              ? 100
              : Math.max.apply(null, rightArr) / 5;
        }
        if (val) {
          this.drawLineBar();
        }
      },
      deep: true,
    },
    xAxis() {
      this.drawLineBar();
    },
    yAxis() {
      this.drawLineBar();
    },
  },
};
</script>

<style scoped lang="scss">
.lineDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  min-height: 400px;
}
</style>
